<template>
  <div class="l-wrapper">
    <div class="q-pa-md">
      <q-input outlined label="Cari Nama Kota" v-model="pagination.searchTerm">
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-markup-table flat bordered>
        <thead>
          <tr>
            <th style="width: 75px">No</th>
            <th>Nama Kota</th>
            <th style="width: 150px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in filteredCity" :key="i">
            <td class="text-center">
              {{ i + 1 + (pagination.currentPage - 1) * 10 }}
            </td>
            <td>{{ val }}</td>
            <td>
              <div class="row justify-evenly">
                <q-btn
                  flat
                  dense
                  color="secondary"
                  icon="edit"
                  @click="
                    type = 'edit';
                    cityName = val;
                    lastName = val;
                    settings = true;
                  "
                ></q-btn>
                <q-btn
                  flat
                  dense
                  color="negative"
                  icon="delete_forever"
                  @click="
                    cityName = val;
                    delguard = true;
                  "
                ></q-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-scroll-area>

    <div class="full-width row justify-center">
      <q-pagination
        v-model="pagination.currentPage"
        :max="pagination.totalPage"
        input
        input-class="text-orange-10"
        class="q-my-md"
      />
      <q-btn
        class="no-border-radius full-width"
        color="primary"
        label="TAMBAH KOTA"
        @click="
          type = 'add';
          settings = true;
        "
      ></q-btn>
    </div>
    <q-dialog v-model="settings" persistent>
      <q-card>
        <q-card-section>
          <q-input outlined label="Nama Kota" v-model="cityName"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Batal"
            @click="cityName = ''"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            v-if="type == 'add'"
            @click="addCity"
            label="Simpan"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            v-if="type == 'edit'"
            @click="editCity"
            label="Edit"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="delguard" persistent>
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menghapus kota <b>{{ cityName }}</b> ?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Tidak"
            @click="cityName = ''"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            @click="delCity"
            label="Ya"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { computed, ref, inject, reactive } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $http = inject("$http");
    const $q = useQuasar();

    let pagination = reactive({
      searchTerm: "",
      currentPage: 1,
      totalPage: 1,
      rowPerPage: 10,
    });
    let listCity = ref([]);

    let getKota = async () => {
      let resp = await $http.get(`/list-kota`);
      listCity.value = resp.data;
    };

    let filteredCity = computed(() => {
      let cities = JSON.parse(JSON.stringify(listCity.value));

      cities = cities.filter((val) => {
        return (
          val.toLowerCase().indexOf(pagination.searchTerm.toLowerCase()) != -1
        );
      });

      let ret = [];
      for (let i = 0; i < 10; i++) {
        if (cities[(pagination.currentPage - 1) * 10 + i]) {
          ret.push(cities[(pagination.currentPage - 1) * 10 + i]);
        }
      }

      pagination.totalPage = Math.ceil(cities.length / pagination.rowPerPage);

      return ret;
    });

    let checkCity = async (val) => {
      let temp = await listCity.value.find((el) => {
        return el.toLowerCase() == val.toLowerCase();
      });
      if (temp) {
        return false;
      } else {
        return true;
      }
    };

    let cityName = ref("");
    let lastName = ref("");

    let addCity = async () => {
      let resp = await checkCity(cityName.value);
      if (resp) {
        let payload = {
          city: cityName.value,
        };
        await $http.post(`/add-kota`, payload);

        $q.notify({ message: "Kota Berhasil Ditambahkan!", color: "positive" });
        await getKota();
        cityName.value = "";
      } else {
        $q.notify({ message: "Kota Sudah Terdaftar!", color: "negative" });
        pagination.searchTerm = cityName.value;
        cityName.value = "";
      }
    };

    let editCity = async () => {
      let resp = checkCity(cityName.value);
      if (resp) {
        let payload = {
          city: cityName.value,
          last_name: lastName.value,
        };
        await $http.post(`/edit-kota`, payload);

        $q.notify({
          message: "Nama Kota Berhasil DIRUBAH!",
          color: "positive",
        });
        await getKota();
        cityName.value = "";
      } else {
        $q.notify({ message: "Kota Sudah Terdaftar!", color: "negative" });
        pagination.searchTerm = cityName.value;
        cityName.value = "";
      }
    };

    let delCity = async () => {
      let payload = {
        city: cityName.value,
      };
      await $http.post(`/hapus-kota`, payload);

      $q.notify({ message: "Kota Berhasil Dihapus!", color: "positive" });
      await getKota();
      cityName.value = "";
    };

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getKota();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      delguard: ref(false),
      settings: ref(false),
      type: ref(""),
      pagination,
      moment,
      store,
      filteredCity,
      cityName,
      lastName,
      getKota,
      addCity,
      editCity,
      delCity,
      checkCity,
    };
  },
};
</script>
